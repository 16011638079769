import * as React from 'react'
import { useState } from 'react'
import { Layout } from '../components/layout'
import { Seo } from '../components/common'
import { TagButtonList, BlogPosts } from '../components/blogs'
import { Skeleton } from '@mui/material'

const BlogIndex = () => {
  const [item, setItem] = useState(false)
  return (
    <Layout>
      <div className="mt-3 w-full relative" style={{ height: '385px', width: '1024px' }}>
        <Skeleton
          variant="rect"
          className="rounded-md shadow-lg absolute top-0 left-0 w-full h-full"
          style={{ opacity: item ? 0 : 1 }}
        />
        <img
          className="rounded-md shadow-lg w-full h-full"
          src={'https://picsum.photos/800/300'}
          alt="My Image"
          onLoad={() => setItem(true)}
          style={{ opacity: item ? 1 : 0, transition: 'opacity 1s ease-in' }}
        />
      </div>
      <p className="text-center mt-3 text-xl text-transparent bg-clip-text bg-gradient-to-r from-red-500 to-blue-500">
        "庾信平生最萧瑟，暮年诗赋动江关。" - 杜甫
      </p>
      <div className="mt-16">
        <TagButtonList checkedName="All" />
      </div>
      <BlogPosts tagName="All" />
    </Layout>
  )
}

export default BlogIndex

export const Head = () => <Seo title="Blogs" />
